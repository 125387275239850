body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ql-editor .mention {
  background-color: #472183;
  color: white;
  padding-left: 4px;
  padding-right: 8px;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 16px;
}

.ql-disabled .ql-editor .mention {
  background-color: #47218320;
  color: black;
  border: solid #472183 1px;
}

.ql-mention-list {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}